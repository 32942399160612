<template>
    <div>
        <devOnly>
            <div v-if="Object.keys(errors)[0]" class="bg-red-800 text-white p-2">
                {{ errors }}
            </div>
        </devOnly>
        <template v-for="notification in notifications">
            <div :class="`bg-${notification.color || stone}-800 text-white p-4 uppercase text-lg`">
                <center><strong>{{ notification.title }}:</strong> {{ notification.description }}</center>
            </div>
        </template>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
        <UModal v-model="errorModal.show" :ui="{ width: 'max-w-6xl mx-12 2xl:mx-48'}" prevent-close aaaa="errorModal.error?.callback ? true : false">
            <UCard v-if="errorModal.error" :ui="{ body: { base: '!p-6 overflow-y-auto max-h-[60vh]'}, header: { background: `!bg-${errorModal.error?.info ? 'indigo' : 'red'}-600 dark:!bg-${errorModal.error?.info ? 'indigo' : 'red'}-800` }}">
                <template #header>
                    <div class="modal-header-wrap">
                        <h3 v-if="errorModal.error?.info" class="text-base font-semibold leading-6 text-neutral-900 dark:text-white flex flex-row items-center">
                            <UIcon class="w-8 h-8 mr-2" name="i-mdi-information-variant-box" />
                            Info {{ errorModal.error.statusCode }}
                        </h3>
                        <h3 v-else class="text-base font-semibold leading-6 text-neutral-900 dark:text-white flex flex-row items-center">
                            <UIcon class="w-8 h-8 mr-2" name="i-mdi-bug" />
                            Error {{ errorModal.error.statusCode }} - ohh, ohhh
                        </h3>
                        <UButton variant="ghost" color="white" icon="i-mdi-close" class="modal-header-close" @click="errorModal.close" />
                    </div>
                </template>
                <!--<div class="min-h-[40vh]">
                    <vue-json-pretty 
                        class="!text-[12px]"
                        itemHeight="20" 
                        :data="errorModalMessage" 
                    />
                </div>
                <p class="mb-6"><u class="text-xs uppercase">Was ist passiert:</u><br>{{ [
                        errorModal.error.statusMessage, 
                        errorModal.error.error.type, 
                        errorModal.error.cause?.message || errorModal.error.cause?.error?.desc || errorModal.error.cause
                    ].filter(e=>e).join(' ... ') }}</p>
                <p v-if="errorModal.error.error.path" class="mb-6"><u class="text-xs uppercase">Was ist betroffen:</u><br>{{ errorModal.error.error.path }}</p>-->
                <p v-if="errorModal.error.description && !errorModal.error?.info" class="font-bold text-xl mb-2">{{ errorModal.error.description }}</p>
                <p v-if="errorModal.error.message" class="font-bold text-lg mb-2">{{ errorModal.error.message }}</p>
                <p v-if="errorModal.error.error" class="mb-4">{{ errorModal.error.error }}</p>
                <p v-if="errorModal.error.solution" class="mb-4">{{ errorModal.error.solution }}</p>
                
                <UButton v-if="!errorModal.extend" @click="errorModal.extend = true" color="gray" variant="solid" size="md" label="Show details" class="mt-4" />
                <template v-if="!errorModal.error?.info && errorModal.extend">
                    <UDivider />
                    <div class="mt-4 mb-0.5 text-sm">Error object:</div>
                    <pre class="whitespace-pre-wrap text-xs bg-stone-400/20 p-4 rounded-md">{{ errorModal.error }}</pre>

                    <template v-if="errorModal.error?.stack?.startsWith('<pre>')">
                        <div class="mt-4 mb-0.5 text-sm">Error stack:</div>
                        <pre class="whitespace-pre-wrap text-xs bg-red-400/20 p-4 rounded-md" v-html="errorModal.error.stack" />
                    </template>
                </template>
                <div class="text-xs mt-4 font-mono"><a @click="">UUID: {{ errorModal.error.uuid }}</a></div>
                <template #footer>
                    <div class="flex space-x-5">
                        <div class="grow"></div>
                        <UButton 
                            @click="errorModal.close"
                            color="primary" 
                            variant="solid" 
                            label="ok, zurück" 
                        />
                    </div>
                </template>
            </UCard>
        </UModal>
        <Teleport to="body">
            <div style="z-index: 99999;">
                <UNotifications />
            </div>
        </Teleport>
    </div>
</template>
<script setup>
    
    /* ROUTE LADEN */
    const route = useRoute();
    const router = useRouter();

    const auth = useAuth();

    const runtimeConfig = useRuntimeConfig();
    const { headers } = useGlobals();
    const appStore = useAppStore()
    const { errors } = storeToRefs(appStore)

    const notifications = ref([])

    definePageMeta({
        title: 'eijns'
    })
    useHead({
        title: 'eijns',
        // meta: [
        //     { name: 'description', content: 'Enhanced Information and Joint Notification System' },
        //     { property: 'og:title', content: 'eijns' },
        // ],
        // bodyAttrs: {
        //     class: 'test'
        // },
        // script: [ { innerHTML: 'console.log(\'Hello world\')' } ]
    })

    const errorModal = ref({
        show: false,
        close: () => {
            //console.log('errorModal.value.error.uuid', errorModal.value.error.uuid)

            if (errorModal.value.error?.callback) {
                errorModal.value.error.callback() 
            }

            nextTick(() => { 
                appStore.clearError(errorModal.value.error.uuid)
                errorModal.value.show = false
                errorModal.value.extend = false
                errorModal.value.error = null
            })
        },
        extend: false,
        error: null
    })

    if (process.client) {
        console.log('onErrorCapured')

        onErrorCaptured((err) => {
            console.log('onErrorCapured', {...err}, err)
            //error.value.show = true
            //error.value.data = {...err}
            //error.value.data['cause']['url'] = window.location
            errorModal.value.show = true
            errorModal.value.extend = false
            errorModal.value.error = {
                ...err,
            }
        })
    }

    watch(errors, (err) => {
        console.log('Object.keys(err)[0]', Object.keys(err)[0])

        //nextTick(() => {
            if (err[Object.keys(err)[0]]?.data && auth.eijnsJWT) {
                console.log('errors', err[Object.keys(err)[0]].data)
                errorModal.value.show = true
                errorModal.value.extend = false
                errorModal.value.error = {
                    //error: err[Object.keys(err)[0]].data,
                    ...err[Object.keys(err)[0]].data.error?._data,
                    info: err[Object.keys(err)[0]].data.info,
                    description: err[Object.keys(err)[0]].data.description,
                    solution: err[Object.keys(err)[0]].data.solution,
                    uuid: err[Object.keys(err)[0]].data.uuid,
                    callback: err[Object.keys(err)[0]].data.callback,
                }
            }
        //})

    }, { deep: true })

    //if (process.client && false) {

    //if (auth.eijnsJWT) {
    watch(() => route.path, (val) => {
        
        if (val === '/home') {
            fetchGlobal.refresh()
        }
    })

    const fetchGlobal = useFetch(
        '/api/globals',
        { 
            method: 'GET',
            headers: headers,
            immediate: true,
            server: false,
            onResponse({ request, response, options }) {
                //console.log('response._data', response._data)

                if (!response.ok) {
                    useError(response, 'Something is wrong while load globals')
                    return
                }

                appStore.set('globals', response._data.data.globals)
                appStore.set('roles', response._data.data.roles)
                appStore.set('me.favourites', response._data.data.me.favourites)
                appStore.set('me.avatar', response._data.data.me.avatar)
                appStore.set('me.email', response._data.data.me.email)
                appStore.set('me.username', response._data.data.me.username)
                appStore.set('me.personal', response._data.data.me.personal)
                appStore.set('me.phone', response._data.data.me.phone)
                appStore.set('me.company', response._data.data.me.company)
                appStore.set('runtimeConfig', {
                    app: runtimeConfig.app,
                    package: runtimeConfig.public.package
                })
            },
            onResponseError({ request, response, options }) {
                useError(response, 'Can\'t load globals')
            },
        }
    )
    //}

        /*const fetchMe =  useFetch(
            '/api/user/me',
            { 
                method: 'GET',
                headers: {
                    ...headers,
                },
                immediate: true,
                server: false,
                onResponse({ request, response, options }) {
                    //console.log('response._data', response._data)

                    if (!response.ok) {
                        useError(response, 'Something is wrong while load your user data')
                        return 
                    }

                    //navigation.value = response._data
                    appStore.set('me.favourites', response._data.favourites)
                    appStore.set('me.avatar', response._data.avatar)
                    appStore.set('me.email', response._data.email)
                    appStore.set('me.username', response._data.username)
                    appStore.set('me.personal', response._data.personal)
                    appStore.set('me.phone', response._data.phone)
                    appStore.set('me.company', response._data.company)
                },
                onResponseError({ request, response, options }) {
                    useError(response, 'Can\'t load user data')
                },
            }
        )*/

        /*const fetchRoles = await useFetch(
            '/api/user/roles',
            { 
                method: 'GET',
                headers: {
                    ...headers,
                },
                immediate: true,
                server: false,
                onResponse({ request, response, options }) {

                    if (!response.ok) {
                        useError(response, 'Something is wrong while loading user roles')
                        this.abort()
                    }

                    appStore.set('roles', response._data.data.map((e) => e.name))
                },
                onResponseError({ request, response, options }) {
                    useError(response, 'Can\'t load user roles')
                }
            }
        )*/
    //}
    //}

    const colorMode = useColorMode()
    //colorMode.preference = 'dark'
    //console.log(colorMode.value)

    const isDark = computed({
        get () {
            return colorMode.value === 'dark'
        },
        set () {
            colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
        }
    })


    /* VUE LIFECYCLE HOOKS */
    onBeforeMount(async () => {
        //document.getElementsByTagName('html')?.[0]?.classList.remove('light')
        //document.getElementsByTagName('html')?.[0]?.classList.add('dark')

        /* LOADING TIMEOUT */
        setTimeout(() => {
            //setMainValue('loading', false)
            //////////mainStoreState.value.loading = false
        }, Math.floor(Math.random() * (4567 - 3456 + 1) + 3456))
    });

    let observerTimeout = null

    const eventOffline = (e) => {
        console.log('offline');
        notifications.value.push({
            id: 'offline',
            color: 'red',
            title: 'Network status',
            description: 'offline',
        })

        setTimeout(() => {
            notifications.value = notifications.value.filter(e => e.id === 'offline')
        }, 7890)
    }

    const eventOnline = (e) => {
        console.log('online');
        notifications.value = notifications.value.filter(e => e.id === 'offline')
        notifications.value.push({
            id: 'online',
            color: 'green',
            title: 'Network status',
            description: 'online',
        })

        setTimeout(() => {
            notifications.value = notifications.value.filter(e => e.id === 'online')
        }, 1234)

        setTimeout(() => {
            notifications.value = notifications.value.filter(e => e.id === 'online')
        }, 7890)
    }

    let jwtTimeout = null
    const fetchJWT = await useFetch(
        '/api/jwt',
        { 
            method: 'GET',
            headers: headers,
            immediate: true,
            server: false,
            onRequest({ request, options }) {
                clearTimeout(jwtTimeout)

                jwtTimeout = setTimeout(() => {
                    fetchJWT.refresh()
                }, 66666)
            },
            onResponse({ request, response, options }) {

                if (!response.ok) {
                    this.onResponseError({ request, response, options })
                    /*useError(response, 'Something is wrong with your JWT', {
                        id: 'jwt',
                        solution: 'You will be automatically logged out.', 
                        callback: () => { window.location.href = '/logout' },
                    })*/
                    this.abort()
                }
            },
            onResponseError({ request, response, options }) {
                ///window.location.href = '/logout'
                // useError(response, 'Wrong or no userdata', {
                //     id: 'jwt',
                //     solution: 'You will be logged out.', 
                //     info: true,
                //     callback: () => { window.location.href = '/logout' },
                // })
            }
        }
    )

    onMounted(async () => {
        /*setTimeout(() => {
            fetchJWT.refresh()
        }, 6666)*/

        nextTick(() => {
            useEvent('offline', eventOffline).setEventTarget(window)
            useEvent('online', eventOnline).setEventTarget(window)
        })

        window.addEventListener('offline', eventOffline);
        window.addEventListener('online', eventOnline);

        const observer = new MutationObserver(() => {
            clearTimeout(observerTimeout)
            const dialogElement = document.querySelector('dialog')
            const modalElement = document.querySelector('[role="dialog"]')

            observerTimeout = setTimeout(() => {

                if (dialogElement || modalElement) {
                    document.getElementById('__nuxt').classList.add('blur-content')
                    //console.log('Dialog detected');
                    //observer.disconnect();
                } else {
                    document.getElementById('__nuxt').classList.remove('blur-content')
                    //console.log('Dialog disappeared');
                    //observer.disconnect();
                }
            }, 34.5)
        });

        // Starten Sie die Beobachtung des DOM auf Änderungen
        observer.observe(document.body, { childList: true, subtree: true });

        window.popStateDetected = false
        window.addEventListener('popstate', () => {
            window.popStateDetected = true
        })

        router.beforeEach((to, from, next) => {
            const IsItABackButton = window.popStateDetected
            window.popStateDetected = false
            if (IsItABackButton && from.meta.someLogica) {
                next(false) 
                return ''
            }
            next()
        })

    });

        /*window.addEventListener('offline', (e) => {
            console.log('offline');
            notifications.value.push({
                id: 'offline',
                color: 'red',
                title: 'Network status',
                description: 'offline',
            })
        });

        window.addEventListener('online', (e) => {
            console.log('online');
        });*/
    onUnmounted(async () => {
        clearTimeout(observerTimeout)
    });

</script>
<style lang="scss">
/* as */
</style>