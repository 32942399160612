import { default as _91_46_46_46catchAll_93grXQqvdbJRMeta } from "/vercel/path0/pages/[...catchAll].vue?macro=true";
import { default as _91token_93MFXx2MNgmgMeta } from "/vercel/path0/pages/confirm/[email]/[token].vue?macro=true";
import { default as error9Slgcyzlc1Meta } from "/vercel/path0/pages/confirm/error.vue?macro=true";
import { default as failROasn1sXcuMeta } from "/vercel/path0/pages/confirm/fail.vue?macro=true";
import { default as successJQZ3o4QsR7Meta } from "/vercel/path0/pages/confirm/success.vue?macro=true";
import { default as timeoutpcGeIcAUaxMeta } from "/vercel/path0/pages/confirm/timeout.vue?macro=true";
import { default as _91_46_46_46catchAll_93Fd6Hm9UNleMeta } from "/vercel/path0/pages/eijns-api/[...catchAll].vue?macro=true";
import { default as indexzTZC2cZjadMeta } from "/vercel/path0/pages/eijns-api/index.vue?macro=true";
import { default as _91_46_46_46catchAll_93KEOHKsb1OzMeta } from "/vercel/path0/pages/eijns-api/reference/[...catchAll].vue?macro=true";
import { default as defaulthxpFpsMqVaMeta } from "/vercel/path0/pages/eijns/default.vue?macro=true";
import { default as index7oCky0wQJFMeta } from "/vercel/path0/pages/eijns/index.vue?macro=true";
import { default as helpgTNWcaY3gjMeta } from "/vercel/path0/pages/help.vue?macro=true";
import { default as homeaAKJ71q3qIMeta } from "/vercel/path0/pages/home.vue?macro=true";
import { default as _91_46_46_46uuid_93XwTZ6f9Lh6Meta } from "/vercel/path0/pages/log/[...uuid].vue?macro=true";
import { default as errorK0tB7HhcNvMeta } from "/vercel/path0/pages/login/error.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as nextqDOoOoyP15Meta } from "/vercel/path0/pages/login/next.vue?macro=true";
import { default as logoutBqDDZXgXsyMeta } from "/vercel/path0/pages/logout.vue?macro=true";
import { default as _91uuid_93eBUZZ0MgAAMeta } from "/vercel/path0/pages/messages/[uuid].vue?macro=true";
import { default as indexpt683rA6b7Meta } from "/vercel/path0/pages/messages/index.vue?macro=true";
import { default as _91uuid_93_32_40Kopie_41NZeZeb4o2PMeta } from "/vercel/path0/pages/notification/[uuid] (Kopie).vue?macro=true";
import { default as _91uuid_93kN2iVCjrUoMeta } from "/vercel/path0/pages/notification/[uuid].vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46default5CMiUix7fBMeta } from "/vercel/path0/pages/notification/[uuid].vue.tab.default.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46emailBuw19ckZSbMeta } from "/vercel/path0/pages/notification/[uuid].vue.tab.email.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46smsBWxSiv5yaDMeta } from "/vercel/path0/pages/notification/[uuid].vue.tab.sms.vue?macro=true";
import { default as indexDao7PpmqbdMeta } from "/vercel/path0/pages/notification/index.vue?macro=true";
import { default as testVda5zT0FWgMeta } from "/vercel/path0/pages/notification/test.vue?macro=true";
import { default as _91token_933YK0KfQONNMeta } from "/vercel/path0/pages/password/[token].vue?macro=true";
import { default as resetProgressO6f6GCydKjMeta } from "/vercel/path0/pages/password/resetProgress.vue?macro=true";
import { default as indexnpJzOswtosMeta } from "/vercel/path0/pages/system/index.vue?macro=true";
import { default as setupR7mHrYyEfdMeta } from "/vercel/path0/pages/system/setup.vue?macro=true";
import { default as setup_46vue_46tab_46datesSG0bajX2ODMeta } from "/vercel/path0/pages/system/setup.vue.tab.dates.vue?macro=true";
import { default as setup_46vue_46tab_46defaultbmiIg120ZUMeta } from "/vercel/path0/pages/system/setup.vue.tab.default.vue?macro=true";
import { default as setup_46vue_46tab_46editorXWDmhm4PytMeta } from "/vercel/path0/pages/system/setup.vue.tab.editor.vue?macro=true";
import { default as setup_46vue_46tab_46structurebQ67FPyY8QMeta } from "/vercel/path0/pages/system/setup.vue.tab.structure.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as _91uuid_93ong0DADVbpMeta } from "/vercel/path0/pages/user/[uuid].vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46addressmSv0lLgNkWMeta } from "/vercel/path0/pages/user/[uuid].vue.tab.address.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46aniversariesKGRZWlXGuxMeta } from "/vercel/path0/pages/user/[uuid].vue.tab.aniversaries.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46credentialsjaLD0aj6diMeta } from "/vercel/path0/pages/user/[uuid].vue.tab.credentials.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46defaultRpcuoklOxlMeta } from "/vercel/path0/pages/user/[uuid].vue.tab.default.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46emergencymqYpHFSzGfMeta } from "/vercel/path0/pages/user/[uuid].vue.tab.emergency.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46personaliEWhabu2s2Meta } from "/vercel/path0/pages/user/[uuid].vue.tab.personal.vue?macro=true";
import { default as indexk3QLtbGbUrMeta } from "/vercel/path0/pages/user/index.vue?macro=true";
import { default as _91token_93YcqAktQC1xMeta } from "/vercel/path0/pages/verify/[token].vue?macro=true";
import { default as _91token_93Xk438UaIMMMeta } from "/vercel/path0/pages/verify/email/[token].vue?macro=true";
import { default as errorvaeyJgPyjfMeta } from "/vercel/path0/pages/verify/email/error.vue?macro=true";
import { default as nextHu1rLMCdOjMeta } from "/vercel/path0/pages/verify/email/next.vue?macro=true";
import { default as successybKaP7BItPMeta } from "/vercel/path0/pages/verify/email/success.vue?macro=true";
import { default as _91token_93vsjXZDOJV1Meta } from "/vercel/path0/pages/verify/password~aksdhbfhsadjfhasd/[token].vue?macro=true";
import { default as homeq5pUrtvVhTMeta } from "/vercel/path0/modules/questionnaire/pages/home.vue?macro=true";
import { default as homeMFUsK93Eu7Meta } from "/vercel/path0/modules/rantomat/pages/home.vue?macro=true";
import { default as indexv7fi8ke6W6Meta } from "/vercel/path0/modules/rantomat/pages/[employeeId]/index.vue?macro=true";
import { default as indexWGVs58Hz2EMeta } from "/vercel/path0/modules/rantomat/pages/[employeeId]/[date]/index.vue?macro=true";
import { default as homeOC7dKLSCyHMeta } from "/vercel/path0/modules/vacationeeze/pages/home.vue?macro=true";
export default [
  {
    name: _91_46_46_46catchAll_93grXQqvdbJRMeta?.name ?? "catchAll",
    path: _91_46_46_46catchAll_93grXQqvdbJRMeta?.path ?? "/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93grXQqvdbJRMeta || {},
    alias: _91_46_46_46catchAll_93grXQqvdbJRMeta?.alias || [],
    redirect: _91_46_46_46catchAll_93grXQqvdbJRMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...catchAll].vue").then(m => m.default || m)
  },
  {
    name: _91token_93MFXx2MNgmgMeta?.name ?? "confirm-email-token",
    path: _91token_93MFXx2MNgmgMeta?.path ?? "/confirm/:email()/:token()",
    meta: _91token_93MFXx2MNgmgMeta || {},
    alias: _91token_93MFXx2MNgmgMeta?.alias || [],
    redirect: _91token_93MFXx2MNgmgMeta?.redirect,
    component: () => import("/vercel/path0/pages/confirm/[email]/[token].vue").then(m => m.default || m)
  },
  {
    name: error9Slgcyzlc1Meta?.name ?? "confirm-error",
    path: error9Slgcyzlc1Meta?.path ?? "/confirm/error",
    meta: error9Slgcyzlc1Meta || {},
    alias: error9Slgcyzlc1Meta?.alias || [],
    redirect: error9Slgcyzlc1Meta?.redirect,
    component: () => import("/vercel/path0/pages/confirm/error.vue").then(m => m.default || m)
  },
  {
    name: failROasn1sXcuMeta?.name ?? "confirm-fail",
    path: failROasn1sXcuMeta?.path ?? "/confirm/fail",
    meta: failROasn1sXcuMeta || {},
    alias: failROasn1sXcuMeta?.alias || [],
    redirect: failROasn1sXcuMeta?.redirect,
    component: () => import("/vercel/path0/pages/confirm/fail.vue").then(m => m.default || m)
  },
  {
    name: successJQZ3o4QsR7Meta?.name ?? "confirm-success",
    path: successJQZ3o4QsR7Meta?.path ?? "/confirm/success",
    meta: successJQZ3o4QsR7Meta || {},
    alias: successJQZ3o4QsR7Meta?.alias || [],
    redirect: successJQZ3o4QsR7Meta?.redirect,
    component: () => import("/vercel/path0/pages/confirm/success.vue").then(m => m.default || m)
  },
  {
    name: timeoutpcGeIcAUaxMeta?.name ?? "confirm-timeout",
    path: timeoutpcGeIcAUaxMeta?.path ?? "/confirm/timeout",
    meta: timeoutpcGeIcAUaxMeta || {},
    alias: timeoutpcGeIcAUaxMeta?.alias || [],
    redirect: timeoutpcGeIcAUaxMeta?.redirect,
    component: () => import("/vercel/path0/pages/confirm/timeout.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46catchAll_93Fd6Hm9UNleMeta?.name ?? "eijns-api-catchAll",
    path: _91_46_46_46catchAll_93Fd6Hm9UNleMeta?.path ?? "/eijns-api/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93Fd6Hm9UNleMeta || {},
    alias: _91_46_46_46catchAll_93Fd6Hm9UNleMeta?.alias || [],
    redirect: _91_46_46_46catchAll_93Fd6Hm9UNleMeta?.redirect,
    component: () => import("/vercel/path0/pages/eijns-api/[...catchAll].vue").then(m => m.default || m)
  },
  {
    name: indexzTZC2cZjadMeta?.name ?? "eijns-api",
    path: indexzTZC2cZjadMeta?.path ?? "/eijns-api",
    meta: indexzTZC2cZjadMeta || {},
    alias: indexzTZC2cZjadMeta?.alias || [],
    redirect: indexzTZC2cZjadMeta?.redirect,
    component: () => import("/vercel/path0/pages/eijns-api/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46catchAll_93KEOHKsb1OzMeta?.name ?? "eijns-api-reference-catchAll",
    path: _91_46_46_46catchAll_93KEOHKsb1OzMeta?.path ?? "/eijns-api/reference/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93KEOHKsb1OzMeta || {},
    alias: _91_46_46_46catchAll_93KEOHKsb1OzMeta?.alias || [],
    redirect: _91_46_46_46catchAll_93KEOHKsb1OzMeta?.redirect,
    component: () => import("/vercel/path0/pages/eijns-api/reference/[...catchAll].vue").then(m => m.default || m)
  },
  {
    name: defaulthxpFpsMqVaMeta?.name ?? "eijns-default",
    path: defaulthxpFpsMqVaMeta?.path ?? "/eijns/default",
    meta: defaulthxpFpsMqVaMeta || {},
    alias: defaulthxpFpsMqVaMeta?.alias || [],
    redirect: defaulthxpFpsMqVaMeta?.redirect,
    component: () => import("/vercel/path0/pages/eijns/default.vue").then(m => m.default || m)
  },
  {
    name: index7oCky0wQJFMeta?.name ?? "eijns",
    path: index7oCky0wQJFMeta?.path ?? "/eijns",
    meta: index7oCky0wQJFMeta || {},
    alias: index7oCky0wQJFMeta?.alias || [],
    redirect: index7oCky0wQJFMeta?.redirect,
    component: () => import("/vercel/path0/pages/eijns/index.vue").then(m => m.default || m)
  },
  {
    name: helpgTNWcaY3gjMeta?.name ?? "help",
    path: helpgTNWcaY3gjMeta?.path ?? "/help",
    meta: helpgTNWcaY3gjMeta || {},
    alias: helpgTNWcaY3gjMeta?.alias || [],
    redirect: helpgTNWcaY3gjMeta?.redirect,
    component: () => import("/vercel/path0/pages/help.vue").then(m => m.default || m)
  },
  {
    name: homeaAKJ71q3qIMeta?.name ?? "home",
    path: homeaAKJ71q3qIMeta?.path ?? "/home",
    meta: homeaAKJ71q3qIMeta || {},
    alias: homeaAKJ71q3qIMeta?.alias || [],
    redirect: homeaAKJ71q3qIMeta?.redirect,
    component: () => import("/vercel/path0/pages/home.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46uuid_93XwTZ6f9Lh6Meta?.name ?? "log-uuid",
    path: _91_46_46_46uuid_93XwTZ6f9Lh6Meta?.path ?? "/log/:uuid(.*)*",
    meta: _91_46_46_46uuid_93XwTZ6f9Lh6Meta || {},
    alias: _91_46_46_46uuid_93XwTZ6f9Lh6Meta?.alias || [],
    redirect: _91_46_46_46uuid_93XwTZ6f9Lh6Meta?.redirect,
    component: () => import("/vercel/path0/pages/log/[...uuid].vue").then(m => m.default || m)
  },
  {
    name: errorK0tB7HhcNvMeta?.name ?? "login-error",
    path: errorK0tB7HhcNvMeta?.path ?? "/login/error",
    meta: errorK0tB7HhcNvMeta || {},
    alias: errorK0tB7HhcNvMeta?.alias || [],
    redirect: errorK0tB7HhcNvMeta?.redirect,
    component: () => import("/vercel/path0/pages/login/error.vue").then(m => m.default || m)
  },
  {
    name: indexoOBDY93e9EMeta?.name ?? "login",
    path: indexoOBDY93e9EMeta?.path ?? "/login",
    meta: indexoOBDY93e9EMeta || {},
    alias: indexoOBDY93e9EMeta?.alias || [],
    redirect: indexoOBDY93e9EMeta?.redirect,
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: nextqDOoOoyP15Meta?.name ?? "login-next",
    path: nextqDOoOoyP15Meta?.path ?? "/login/next",
    meta: nextqDOoOoyP15Meta || {},
    alias: nextqDOoOoyP15Meta?.alias || [],
    redirect: nextqDOoOoyP15Meta?.redirect,
    component: () => import("/vercel/path0/pages/login/next.vue").then(m => m.default || m)
  },
  {
    name: logoutBqDDZXgXsyMeta?.name ?? "logout",
    path: logoutBqDDZXgXsyMeta?.path ?? "/logout",
    meta: logoutBqDDZXgXsyMeta || {},
    alias: logoutBqDDZXgXsyMeta?.alias || [],
    redirect: logoutBqDDZXgXsyMeta?.redirect,
    component: () => import("/vercel/path0/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93eBUZZ0MgAAMeta?.name ?? "messages-uuid",
    path: _91uuid_93eBUZZ0MgAAMeta?.path ?? "/messages/:uuid()",
    meta: _91uuid_93eBUZZ0MgAAMeta || {},
    alias: _91uuid_93eBUZZ0MgAAMeta?.alias || [],
    redirect: _91uuid_93eBUZZ0MgAAMeta?.redirect,
    component: () => import("/vercel/path0/pages/messages/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexpt683rA6b7Meta?.name ?? "messages",
    path: indexpt683rA6b7Meta?.path ?? "/messages",
    meta: indexpt683rA6b7Meta || {},
    alias: indexpt683rA6b7Meta?.alias || [],
    redirect: indexpt683rA6b7Meta?.redirect,
    component: () => import("/vercel/path0/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93_32_40Kopie_41NZeZeb4o2PMeta?.name ?? "notification-uuid (Kopie)",
    path: _91uuid_93_32_40Kopie_41NZeZeb4o2PMeta?.path ?? "/notification/:uuid()%20(Kopie)",
    meta: _91uuid_93_32_40Kopie_41NZeZeb4o2PMeta || {},
    alias: _91uuid_93_32_40Kopie_41NZeZeb4o2PMeta?.alias || [],
    redirect: _91uuid_93_32_40Kopie_41NZeZeb4o2PMeta?.redirect,
    component: () => import("/vercel/path0/pages/notification/[uuid] (Kopie).vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93kN2iVCjrUoMeta?.name ?? "notification-uuid",
    path: _91uuid_93kN2iVCjrUoMeta?.path ?? "/notification/:uuid()",
    meta: _91uuid_93kN2iVCjrUoMeta || {},
    alias: _91uuid_93kN2iVCjrUoMeta?.alias || [],
    redirect: _91uuid_93kN2iVCjrUoMeta?.redirect,
    component: () => import("/vercel/path0/pages/notification/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93_46vue_46tab_46default5CMiUix7fBMeta?.name ?? "notification-uuid.vue.tab.default",
    path: _91uuid_93_46vue_46tab_46default5CMiUix7fBMeta?.path ?? "/notification/:uuid().vue.tab.default",
    meta: _91uuid_93_46vue_46tab_46default5CMiUix7fBMeta || {},
    alias: _91uuid_93_46vue_46tab_46default5CMiUix7fBMeta?.alias || [],
    redirect: _91uuid_93_46vue_46tab_46default5CMiUix7fBMeta?.redirect,
    component: () => import("/vercel/path0/pages/notification/[uuid].vue.tab.default.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93_46vue_46tab_46emailBuw19ckZSbMeta?.name ?? "notification-uuid.vue.tab.email",
    path: _91uuid_93_46vue_46tab_46emailBuw19ckZSbMeta?.path ?? "/notification/:uuid().vue.tab.email",
    meta: _91uuid_93_46vue_46tab_46emailBuw19ckZSbMeta || {},
    alias: _91uuid_93_46vue_46tab_46emailBuw19ckZSbMeta?.alias || [],
    redirect: _91uuid_93_46vue_46tab_46emailBuw19ckZSbMeta?.redirect,
    component: () => import("/vercel/path0/pages/notification/[uuid].vue.tab.email.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93_46vue_46tab_46smsBWxSiv5yaDMeta?.name ?? "notification-uuid.vue.tab.sms",
    path: _91uuid_93_46vue_46tab_46smsBWxSiv5yaDMeta?.path ?? "/notification/:uuid().vue.tab.sms",
    meta: _91uuid_93_46vue_46tab_46smsBWxSiv5yaDMeta || {},
    alias: _91uuid_93_46vue_46tab_46smsBWxSiv5yaDMeta?.alias || [],
    redirect: _91uuid_93_46vue_46tab_46smsBWxSiv5yaDMeta?.redirect,
    component: () => import("/vercel/path0/pages/notification/[uuid].vue.tab.sms.vue").then(m => m.default || m)
  },
  {
    name: indexDao7PpmqbdMeta?.name ?? "notification",
    path: indexDao7PpmqbdMeta?.path ?? "/notification",
    meta: indexDao7PpmqbdMeta || {},
    alias: indexDao7PpmqbdMeta?.alias || [],
    redirect: indexDao7PpmqbdMeta?.redirect,
    component: () => import("/vercel/path0/pages/notification/index.vue").then(m => m.default || m)
  },
  {
    name: testVda5zT0FWgMeta?.name ?? "notification-test",
    path: testVda5zT0FWgMeta?.path ?? "/notification/test",
    meta: testVda5zT0FWgMeta || {},
    alias: testVda5zT0FWgMeta?.alias || [],
    redirect: testVda5zT0FWgMeta?.redirect,
    component: () => import("/vercel/path0/pages/notification/test.vue").then(m => m.default || m)
  },
  {
    name: _91token_933YK0KfQONNMeta?.name ?? "password-token",
    path: _91token_933YK0KfQONNMeta?.path ?? "/password/:token()",
    meta: _91token_933YK0KfQONNMeta || {},
    alias: _91token_933YK0KfQONNMeta?.alias || [],
    redirect: _91token_933YK0KfQONNMeta?.redirect,
    component: () => import("/vercel/path0/pages/password/[token].vue").then(m => m.default || m)
  },
  {
    name: resetProgressO6f6GCydKjMeta?.name ?? "password-resetProgress",
    path: resetProgressO6f6GCydKjMeta?.path ?? "/password/resetProgress",
    meta: resetProgressO6f6GCydKjMeta || {},
    alias: resetProgressO6f6GCydKjMeta?.alias || [],
    redirect: resetProgressO6f6GCydKjMeta?.redirect,
    component: () => import("/vercel/path0/pages/password/resetProgress.vue").then(m => m.default || m)
  },
  {
    name: indexnpJzOswtosMeta?.name ?? "system",
    path: indexnpJzOswtosMeta?.path ?? "/system",
    meta: indexnpJzOswtosMeta || {},
    alias: indexnpJzOswtosMeta?.alias || [],
    redirect: indexnpJzOswtosMeta?.redirect,
    component: () => import("/vercel/path0/pages/system/index.vue").then(m => m.default || m)
  },
  {
    name: setupR7mHrYyEfdMeta?.name ?? "system-setup",
    path: setupR7mHrYyEfdMeta?.path ?? "/system/setup",
    meta: setupR7mHrYyEfdMeta || {},
    alias: setupR7mHrYyEfdMeta?.alias || [],
    redirect: setupR7mHrYyEfdMeta?.redirect,
    component: () => import("/vercel/path0/pages/system/setup.vue").then(m => m.default || m)
  },
  {
    name: setup_46vue_46tab_46datesSG0bajX2ODMeta?.name ?? "system-setup.vue.tab.dates",
    path: setup_46vue_46tab_46datesSG0bajX2ODMeta?.path ?? "/system/setup.vue.tab.dates",
    meta: setup_46vue_46tab_46datesSG0bajX2ODMeta || {},
    alias: setup_46vue_46tab_46datesSG0bajX2ODMeta?.alias || [],
    redirect: setup_46vue_46tab_46datesSG0bajX2ODMeta?.redirect,
    component: () => import("/vercel/path0/pages/system/setup.vue.tab.dates.vue").then(m => m.default || m)
  },
  {
    name: setup_46vue_46tab_46defaultbmiIg120ZUMeta?.name ?? "system-setup.vue.tab.default",
    path: setup_46vue_46tab_46defaultbmiIg120ZUMeta?.path ?? "/system/setup.vue.tab.default",
    meta: setup_46vue_46tab_46defaultbmiIg120ZUMeta || {},
    alias: setup_46vue_46tab_46defaultbmiIg120ZUMeta?.alias || [],
    redirect: setup_46vue_46tab_46defaultbmiIg120ZUMeta?.redirect,
    component: () => import("/vercel/path0/pages/system/setup.vue.tab.default.vue").then(m => m.default || m)
  },
  {
    name: setup_46vue_46tab_46editorXWDmhm4PytMeta?.name ?? "system-setup.vue.tab.editor",
    path: setup_46vue_46tab_46editorXWDmhm4PytMeta?.path ?? "/system/setup.vue.tab.editor",
    meta: setup_46vue_46tab_46editorXWDmhm4PytMeta || {},
    alias: setup_46vue_46tab_46editorXWDmhm4PytMeta?.alias || [],
    redirect: setup_46vue_46tab_46editorXWDmhm4PytMeta?.redirect,
    component: () => import("/vercel/path0/pages/system/setup.vue.tab.editor.vue").then(m => m.default || m)
  },
  {
    name: setup_46vue_46tab_46structurebQ67FPyY8QMeta?.name ?? "system-setup.vue.tab.structure",
    path: setup_46vue_46tab_46structurebQ67FPyY8QMeta?.path ?? "/system/setup.vue.tab.structure",
    meta: setup_46vue_46tab_46structurebQ67FPyY8QMeta || {},
    alias: setup_46vue_46tab_46structurebQ67FPyY8QMeta?.alias || [],
    redirect: setup_46vue_46tab_46structurebQ67FPyY8QMeta?.redirect,
    component: () => import("/vercel/path0/pages/system/setup.vue.tab.structure.vue").then(m => m.default || m)
  },
  {
    name: testlK5QpQxp8eMeta?.name ?? "test",
    path: testlK5QpQxp8eMeta?.path ?? "/test",
    meta: testlK5QpQxp8eMeta || {},
    alias: testlK5QpQxp8eMeta?.alias || [],
    redirect: testlK5QpQxp8eMeta?.redirect,
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93ong0DADVbpMeta?.name ?? "user-uuid",
    path: _91uuid_93ong0DADVbpMeta?.path ?? "/user/:uuid()",
    meta: _91uuid_93ong0DADVbpMeta || {},
    alias: _91uuid_93ong0DADVbpMeta?.alias || [],
    redirect: _91uuid_93ong0DADVbpMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93_46vue_46tab_46addressmSv0lLgNkWMeta?.name ?? "user-uuid.vue.tab.address",
    path: _91uuid_93_46vue_46tab_46addressmSv0lLgNkWMeta?.path ?? "/user/:uuid().vue.tab.address",
    meta: _91uuid_93_46vue_46tab_46addressmSv0lLgNkWMeta || {},
    alias: _91uuid_93_46vue_46tab_46addressmSv0lLgNkWMeta?.alias || [],
    redirect: _91uuid_93_46vue_46tab_46addressmSv0lLgNkWMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.address.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93_46vue_46tab_46aniversariesKGRZWlXGuxMeta?.name ?? "user-uuid.vue.tab.aniversaries",
    path: _91uuid_93_46vue_46tab_46aniversariesKGRZWlXGuxMeta?.path ?? "/user/:uuid().vue.tab.aniversaries",
    meta: _91uuid_93_46vue_46tab_46aniversariesKGRZWlXGuxMeta || {},
    alias: _91uuid_93_46vue_46tab_46aniversariesKGRZWlXGuxMeta?.alias || [],
    redirect: _91uuid_93_46vue_46tab_46aniversariesKGRZWlXGuxMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.aniversaries.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93_46vue_46tab_46credentialsjaLD0aj6diMeta?.name ?? "user-uuid.vue.tab.credentials",
    path: _91uuid_93_46vue_46tab_46credentialsjaLD0aj6diMeta?.path ?? "/user/:uuid().vue.tab.credentials",
    meta: _91uuid_93_46vue_46tab_46credentialsjaLD0aj6diMeta || {},
    alias: _91uuid_93_46vue_46tab_46credentialsjaLD0aj6diMeta?.alias || [],
    redirect: _91uuid_93_46vue_46tab_46credentialsjaLD0aj6diMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.credentials.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93_46vue_46tab_46defaultRpcuoklOxlMeta?.name ?? "user-uuid.vue.tab.default",
    path: _91uuid_93_46vue_46tab_46defaultRpcuoklOxlMeta?.path ?? "/user/:uuid().vue.tab.default",
    meta: _91uuid_93_46vue_46tab_46defaultRpcuoklOxlMeta || {},
    alias: _91uuid_93_46vue_46tab_46defaultRpcuoklOxlMeta?.alias || [],
    redirect: _91uuid_93_46vue_46tab_46defaultRpcuoklOxlMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.default.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93_46vue_46tab_46emergencymqYpHFSzGfMeta?.name ?? "user-uuid.vue.tab.emergency",
    path: _91uuid_93_46vue_46tab_46emergencymqYpHFSzGfMeta?.path ?? "/user/:uuid().vue.tab.emergency",
    meta: _91uuid_93_46vue_46tab_46emergencymqYpHFSzGfMeta || {},
    alias: _91uuid_93_46vue_46tab_46emergencymqYpHFSzGfMeta?.alias || [],
    redirect: _91uuid_93_46vue_46tab_46emergencymqYpHFSzGfMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.emergency.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93_46vue_46tab_46personaliEWhabu2s2Meta?.name ?? "user-uuid.vue.tab.personal",
    path: _91uuid_93_46vue_46tab_46personaliEWhabu2s2Meta?.path ?? "/user/:uuid().vue.tab.personal",
    meta: _91uuid_93_46vue_46tab_46personaliEWhabu2s2Meta || {},
    alias: _91uuid_93_46vue_46tab_46personaliEWhabu2s2Meta?.alias || [],
    redirect: _91uuid_93_46vue_46tab_46personaliEWhabu2s2Meta?.redirect,
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.personal.vue").then(m => m.default || m)
  },
  {
    name: indexk3QLtbGbUrMeta?.name ?? "user",
    path: indexk3QLtbGbUrMeta?.path ?? "/user",
    meta: indexk3QLtbGbUrMeta || {},
    alias: indexk3QLtbGbUrMeta?.alias || [],
    redirect: indexk3QLtbGbUrMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93YcqAktQC1xMeta?.name ?? "verify-token",
    path: _91token_93YcqAktQC1xMeta?.path ?? "/verify/:token()",
    meta: _91token_93YcqAktQC1xMeta || {},
    alias: _91token_93YcqAktQC1xMeta?.alias || [],
    redirect: _91token_93YcqAktQC1xMeta?.redirect,
    component: () => import("/vercel/path0/pages/verify/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Xk438UaIMMMeta?.name ?? "verify-email-token",
    path: _91token_93Xk438UaIMMMeta?.path ?? "/verify/email/:token()",
    meta: _91token_93Xk438UaIMMMeta || {},
    alias: _91token_93Xk438UaIMMMeta?.alias || [],
    redirect: _91token_93Xk438UaIMMMeta?.redirect,
    component: () => import("/vercel/path0/pages/verify/email/[token].vue").then(m => m.default || m)
  },
  {
    name: errorvaeyJgPyjfMeta?.name ?? "verify-email-error",
    path: errorvaeyJgPyjfMeta?.path ?? "/verify/email/error",
    meta: errorvaeyJgPyjfMeta || {},
    alias: errorvaeyJgPyjfMeta?.alias || [],
    redirect: errorvaeyJgPyjfMeta?.redirect,
    component: () => import("/vercel/path0/pages/verify/email/error.vue").then(m => m.default || m)
  },
  {
    name: nextHu1rLMCdOjMeta?.name ?? "verify-email-next",
    path: nextHu1rLMCdOjMeta?.path ?? "/verify/email/next",
    meta: nextHu1rLMCdOjMeta || {},
    alias: nextHu1rLMCdOjMeta?.alias || [],
    redirect: nextHu1rLMCdOjMeta?.redirect,
    component: () => import("/vercel/path0/pages/verify/email/next.vue").then(m => m.default || m)
  },
  {
    name: successybKaP7BItPMeta?.name ?? "verify-email-success",
    path: successybKaP7BItPMeta?.path ?? "/verify/email/success",
    meta: successybKaP7BItPMeta || {},
    alias: successybKaP7BItPMeta?.alias || [],
    redirect: successybKaP7BItPMeta?.redirect,
    component: () => import("/vercel/path0/pages/verify/email/success.vue").then(m => m.default || m)
  },
  {
    name: _91token_93vsjXZDOJV1Meta?.name ?? "verify-password~aksdhbfhsadjfhasd-token",
    path: _91token_93vsjXZDOJV1Meta?.path ?? "/verify/password~aksdhbfhsadjfhasd/:token()",
    meta: _91token_93vsjXZDOJV1Meta || {},
    alias: _91token_93vsjXZDOJV1Meta?.alias || [],
    redirect: _91token_93vsjXZDOJV1Meta?.redirect,
    component: () => import("/vercel/path0/pages/verify/password~aksdhbfhsadjfhasd/[token].vue").then(m => m.default || m)
  },
  {
    name: homeq5pUrtvVhTMeta?.name ?? "questionnaire",
    path: homeq5pUrtvVhTMeta?.path ?? "/questionnaire",
    meta: homeq5pUrtvVhTMeta || {},
    alias: homeq5pUrtvVhTMeta?.alias || [],
    redirect: homeq5pUrtvVhTMeta?.redirect,
    component: () => import("/vercel/path0/modules/questionnaire/pages/home.vue").then(m => m.default || m)
  },
  {
    name: homeMFUsK93Eu7Meta?.name ?? "rantomat",
    path: homeMFUsK93Eu7Meta?.path ?? "/rantomat/",
    meta: homeMFUsK93Eu7Meta || {},
    alias: homeMFUsK93Eu7Meta?.alias || [],
    redirect: homeMFUsK93Eu7Meta?.redirect,
    component: () => import("/vercel/path0/modules/rantomat/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexv7fi8ke6W6Meta?.name ?? "rantomatEmployeeDays",
    path: indexv7fi8ke6W6Meta?.path ?? "/rantomat/:employeeId?/",
    meta: indexv7fi8ke6W6Meta || {},
    alias: indexv7fi8ke6W6Meta?.alias || [],
    redirect: indexv7fi8ke6W6Meta?.redirect,
    component: () => import("/vercel/path0/modules/rantomat/pages/[employeeId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWGVs58Hz2EMeta?.name ?? "rantomatEmployeeDayDetail",
    path: indexWGVs58Hz2EMeta?.path ?? "/rantomat/:employeeId?/:date?/",
    meta: indexWGVs58Hz2EMeta || {},
    alias: indexWGVs58Hz2EMeta?.alias || [],
    redirect: indexWGVs58Hz2EMeta?.redirect,
    component: () => import("/vercel/path0/modules/rantomat/pages/[employeeId]/[date]/index.vue").then(m => m.default || m)
  },
  {
    name: homeOC7dKLSCyHMeta?.name ?? "vacationeeze",
    path: homeOC7dKLSCyHMeta?.path ?? "/vacationeeze",
    meta: homeOC7dKLSCyHMeta || {},
    alias: homeOC7dKLSCyHMeta?.alias || [],
    redirect: homeOC7dKLSCyHMeta?.redirect,
    component: () => import("/vercel/path0/modules/vacationeeze/pages/home.vue").then(m => m.default || m)
  }
]