export default defineAppConfig({
    ui: {
        icons: {
            dynamic: true,
        },
        primary: 'indigo',
        stone: 'stone',
        gray: 'stone',
        icon: 'inline-block w-6 h-6 align-middle',
        skeleton: {
            base: 'animate-pulse brightness-150'
        },
        tooltip: {
            container: 'z-30',
            default: {
                openDelay: 666,
            },
        },
        toggle: {
            inactive: 'bg-red-700/30 dark:bg-red-500/30',
            // default: {
            //     onIcon: 'i-mdi-check',
            //     offIcon: 'i-mdi-close',
            //     color: 'lime',
            // },
        },
        /*tooltip: {
            base: 'delay-500 [@media(pointer:coarse)]:hidden h-8 px-3 py-2 text-xs font-bold truncate tracking-tight uppercase bg',
            shadow: '!shadow-2xl !shadow-primary-100/10',
            background: '!bg-primary-900',
            color: '!text-white',
            rounded: '!rounded-sm',
            ring: 'ring-1 ring-primary-200 dark:ring-primary-600',
        },*/
        table: {
            wrapper: '!overflow-visible hover-tbody',
            thead: '!sticky !-top-[1px] bg-stone-900 z-10 shadow-lg shadow-stone-300 dark:shadow-stone-900',
            divide: '',
            tr: {
                base: 'border-b border-stone-300/80 dark:border-stone-500/20',
                color: '',
                active: 'hover:bg-stone-100 dark:hover:bg-stone-800/50 cursor-pointer',
            },
            tbody: '',
            th: {
                base: 'first:rounded-tl-md last:rounded-tr-md cursor-default uppercase font-bold text-left border-b-1 border-stone-300 dark:border-stone-950/40 !bg-stone-600 dark:!bg-stone-700/50',
                color: 'text-stone-200',
                size: 'text-sm',
            },
            td: {
                color: '!text-black dark:!text-white',
                size: 'text-md',
            }
        },
        notification: {
            shadow: 'shadow-xl shadow-black/60 dark:shadow-primary-500/50',  
            title: 'text-lg font-medium text-stone-900 dark:text-white',
            description: 'mt-1 text-md leading-6 text-stone-500 dark:text-stone-400',
            icon: {
                base: 'flex-shrink-0 w-8 h-8'
            }
        },
        textarea: {
            base: 'bg-transparent relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0',
            rounded: 'rounded-sm',
            default: {
                size: 'xl',
            },
            color: {
                white: {
                    outline: "shadow-sm bg-transparent dark:bg-transparent text-stone-900 dark:text-white ring-0 focus:ring-0",
                }
            }
        },
        formGroup: {
            wrapper: 'relative bg-stone-100 dark:bg-stone-800/50 group pt-6 mt-0 relative ring-1 ring-stone-700/30 dark:ring-stone-700/50 focus-within:ring-2 focus-within:ring-primary-400 dark:focus-within:ring-2 dark:focus-within:ring-primary-400 min-h-[72px] form-group rounded-sm',
            label: {
                wrapper: 'absolute w-full top-3.5 left-3.5 z-0',
                base: 'text-xs font-bold absolute w-full top-0 left-0 text-xs z-10 uppercase opacity-60 tracking-widest',
                required: 'after:content-["*"] after:text-xl after:ms-0.5 after:-mt-2 after:absolute after:text-red-500 dark:after:text-red-400',
            },
            hint: 'absolute top-0 right-4 pr-4 uppercase text-xs text-stone-500 dark:text-stone-400',
            help: 'w-full bg-stone-500/20 -mt-1 pt-2 pl-4 pb-2 text-xs text-stone-500 dark:text-stone-400',
            error: 'w-full bg-red-600 -mt-1 pt-2 pl-4 pb-2 text-xs font-black text-white dark:!text-white',
        },
        input: {
            base: 'ppy-4 w-full !border-none !ring-0 -mpt-[2px]',
            rounded: 'rounded-sm',
            color: {
                white: {
                    outline: 'bg-transparent dark:bg-transparent ring-1 ring-inset ring-transparent focus:ring-0 focus:ring-primary-500'
                },
                stone: {
                    outline: 'bg-transparent dark:bg-transparent ring-1 ring-inset ring-transparent focus:ring-0 focus:ring-primary-500'
                }
            },
            default: {
                size: 'xl',
            },
            label: {
                wrapper: 'text-xs absolute left-4 top-4 text-stone-400 uppercase',
            },
            icon: {
                /*base: 'group-[.indent]:w-8 group-[.indent]:h-8 -mmt-6',
                trailing: {
                    wrapper: 'block absolute inset-y-0 right-0 flex items-center pointer-events-auto',
                },*/
            },
        },
        slideover: {
            background: 'bg-stone-900',
            base: 'overflow-hidden focus:outline-none p-4',
        },
        dropdown: {
            background: 'bg-stone-900',
            rounded: 'rounded-md',
            shadow: 'shadow-2xl shadow-black/60 dark:shadow-primary-500/50',
            base: 'overflow-hidden focus:outline-none p-0',
            padding: '',
            item: {
                base: 'group flex items-center gap-2 w-full !p-2.5 !px-4',
                rounded: '',
                active: 'bg-stone-100 dark:bg-stone-900 text-stone-900 dark:text-white hover:!bg-stone-800',
                icon: {
                    base: 'flex-shrink-0 h-6 w-6',
                },
                shortcuts: 'hidden md:inline-flex flex-shrink-0 pl-8 gap-1 ms-auto'
            },
            transition: {
                enterActiveClass: 'transition ease-out duration-200',
                enterFromClass: 'opacity-0 translate-y-1',
                enterToClass: 'opacity-100 translate-y-0',
                leaveActiveClass: 'transition ease-in duration-150',
                leaveFromClass: 'opacity-100 translate-y-0',
                leaveToClass: 'opacity-0 translate-y-1'
            },
        },
        popover: {
            background: 'bg-stone-50 dark:bg-stone-900',
            rounded: 'rounded-md',
            shadow: 'shadow-2xl shadow-black/60 dark:shadow-primary-500/100',
            base: 'overflow-hidden focus:outline-none p-4 text-black dark:text-white',
            rring: 'ring-1 ring-primary-500/20',
            ring: 'ring-8 ring-stone-100/40 dark:ring-black/20',
        },
        contextMenu: {
            background: 'dark:bg-stone-900',
            rounded: 'rounded-md',
            shadow: 'shadow-2xl shadow-black/60 dark:shadow-primary-500/50',
            base: 'overflow-hidden focus:outline-none p-4',
            ring: 'ring-1 ring-primary-500/20',
        },
        select: {
            base: 'w-full ppy-4 !ring-0 -pmt-[2px]',
            rounded: 'rounded-md',
            default: {
                size: 'xl',
            },
            color: {
                white: {
                    outline: "shadow-none !bg-transparent text-stone-900 dark:text-white ring-0 focus:ring-0",
                }
            },
            icon: {
                /*base: 'group-[.indent]:w-10 group-[.indent]:h-10 group-[.indent]:-mt-6',
                trailing: {
                    wrapper: 'block absolute inset-y-0 right-0 flex items-center pointer-events-auto w',
                },*/
            },
        },
        aavatar: {
            chip: {
                background: 'animate-ping',
            },
        },
        selectMenu: {
            rounded: 'rounded-sm',
            padding: 'p-0',
            height: 'max-h-[30vh]',
            base: 'relative focus:outline-none overflow-y-auto scroll-py-1',
            input: 'bg-primary-100 dark:bg-primary-600 block w-[calc(100%+.25rem)] focus:ring-transparent text-sm px-3 py-2.5 text-gray-700 dark:text-gray-200 border-0 border-b border-gray-200 dark:border-gray-700 focus:border-inherit sticky -top-0 -mt-0 mb-1 -mx-1 z-10 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none',
            option: {
                bbase: 'ring-1 ring-primary-500',
                bbbase: 'cursor-pointer select-none relative flex items-center justify-between gap-1 hover:!bg-red-900',
                bvvorder: 'border-2 border-primary-600',
                container: 'flex items-center gap-2 min-w-0 w-full',
                rounded: 'rounded-none',
                padding: 'px-2 py-3',
            },
            hhheight: '!translate-y-0',
            ring: 'ring-1 !ring-primary-500/40',
            llabel: {
                wrapper: "text-xs absolute left-4 top-4 text-stone-400 uppercase",
            },
            shadow: 'shadow-2xl shadow-black/60 dark:shadow-primary-500/50',
            sshadow: '!shadow-[0_25px_50px_75px_rgba(255,255,255,1)] dsark:!shadow-[0_25px_50px_75px_rgba(28,25,23,1)]',
        },
        buttonGroup: {
            rrounded: 'rounded-none',
        },
        button: {
            base: 'font-bold uppercase text-sm tracking-widest cursor-pointer disabled:cursor-default disabled:opacity-30 enabled:opacity-100',
            rounded: 'rounded-md',
            default: {
                size: 'xl',
                loadingIcon: 'mdi:loading',
            },
            cssolor: {
                wshite: {
                    gghost: 'text-white hover:text-stone-900',
                },
                gsray: {
                    gghost: 'dark:text-white hover:bg-black/5',
                },
            },
            ccolor: {
                stone: {
                    soft: 'bg-stone-100 dark:bg-stone-800 enabled:hover:bg-stone-200 dark:enabled:hover:bg-stone-700 text-stone-700 dark:text-stone-500 enabled:hover:text-stone-200',
                    solid: 'bg-stone-100 dark:text-stone-800 enabled:hover:bg-stone-200 dark:enabled:hover:bg-stone-700 text-black dark:text-white'
                },
                white: {
                    ghost: '!text-white !bg-transparent hover:!text-stone-900 hover:!bg-white/60',
                },
                gray: {
                    ghost: 'text-stone-600 dark:text-white hover:bg-white dark:hover:bg-stone-800',
                    soft: 'text-stone-600 dark:text-white hover:text-white dark:hover:text-stone-700 bg-stone-200 dark:bg-stone-950/50 hover:bg-stone-500 dark:hover:bg-stone-700',
                },
                red: {
                    solid: 'bg-red-800 enabled:hover:bg-red-700 text-white'
                },
                green: {
                    solid: 'bg-lime-700 enabled:hover:bg-lime-600 text-white'
                },
                primary: {
                    solid: 'text-white bg-primary-700 enabled:hover:bg-primary-500',
                    link: 'bg-primary-700 hover:bg-primary-500'
                }
            }
        },
        modal: {
            ring: 'ring-8 ring-white/30 dark:ring-black/20',
            width: 'max-w-[50vw] min-w-fit',
            heightt: 'max-h-[calc(100vh-5rem)] min-h-fit',
            shadow: 'shadow-2xl shadow-black/30 dark:shadow-primary-600/20',
            overlay: {
                background: '!bg-stone-100/80 dark:!bg-stone-900/80 bg-gradient-to-r from-yellow-500/40 via-primary-500/40 to-primary-500/40 animate-rotate-bg backdrop-blur',
            },
            transition: {
                leave: '!transition-none',
                leaveTo: '!left-[14px] !translate-x-0',
            }
        },
        card: {
            rounded: 'rounded-sm',
            bbase: 'text-black dark:text-white',
            shadow: 'shadow-2xl shadow-black/60 dark:shadow-primary-500/100',
            background: 'bg-stone-100 dark:bg-stone-900',
            header: {
                base: 'text-lg font-bold uppercase tracking-widest !text-white',
                background: 'bg-primary-600 dark:bg-primary-900',
                padding: 'p-6',
            },
            body: {
                base: 'text-black dark:text-white',
                background: '',
            },
            footer: {
                base: 'bg-stone-200 dark:bg-black/30',
                padding: 'p-6',
            }
        },
        checkbox: {
            base: 'h-6 w-6',
            label: 'cursor-pointer text-[16px]',
        },
        radio: {
            base: 'h-6 w-6',
            label: 'cursor-pointer text-[16px]',
        },
        alert: {
            padding: 'p-4',
            title: 'text-lg uppercase tracking-widest font-bold',
            description: 'mt-0 leading-5',
            icon: { 
                base: 'flex-shrink-0 w-12 h-12 mx-0',
            }
        },
        ttttabs: {
            wrapper: 'mb-8 mx-0',
            list: {
                background: 'bg-transparent',
                height: 'h-14',
                padding: 'p-2',
                marker: {
                    background: '!bg-primary-300 dark:!bg-primary-900',
                },
                tab: {
                    base: 'uppercase tracking-widest',
                    height: 'h-10',
                }
            }
        },
        tttabs: {
            wrapper: 'mb-8 mx-0',
            list: {
                base: 'gap-2 !flex flex-row',
                height: 'h-14',
                padding: 'p-2',
                background: '!bg-transparent',
                marker: {
                    background: '!bg-primary-600',
                    width: '!w-fit',
                    rounded: 'rounded-none rounded-b-lg',
                },
                tab: {
                    base: 'w-fit uppercase tracking-widest',
                    height: 'h-10',
                    padding: 'px-4 py-8',
                    active: 'text-black dark:text-white',
                    inactive: 'text-black/60 dark:text-white/60 hover:bg-stone-700/50',
                    rounded: 'rounded-none rounded-b-lg',
                }
            }
        },
        ttabs: {
            wrapper: 'mb-8',
            container: 'w-auto border-b-2 border-stone-500',
            background: 'bg-transparent',
            list: {
                base: 'flex flex-row',
                background: '!bg-transparent',
                marker: {
                    base: 'border-b-2 border-primary-400 z-20 absolute -bottom-[10px]',
                    background: '!bg-transparent',
                    rounded: 'rounded-none',
                },
                tab: {
                    base: 'w-auto',
                    background: 'bg-transparent',
                    height: 'h-10',
                    padding: 'uppercase tracking-widest',
                    rounded: 'rounded-none',
                    active: 'text-black dark:text-white',
                    inactive: 'text-black/60 dark:text-white/60'
                }
            }
        }
    }
})