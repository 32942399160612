const __filename = '/modules/rantomat/plugins/index.js';
import chalk from 'chalk';
import dayjs from 'dayjs'
import 'dayjs/locale/de.js'
dayjs.locale('de')
import units from '~/composables/data/units.js'
import monthNames from '~/composables/data/monthNames.js'
import { defineNuxtPlugin } from '#app'

import agreements from '../agreements.json'
import credentials from '../credentials.json'
import navigation from '../navigation.json'
import notifications from '../notifications.json'

import setupPage from '../pages/setup/tab.default.vue'

export default defineNuxtPlugin((nuxtApp) => {

    if (!nuxtApp.$eijnsApp) {
        nuxtApp.provide('eijnsApp', {})
    }

    nuxtApp.$eijnsApp.agreements = {
        ...nuxtApp?.$eijnsApp?.agreements,
        ...agreements,
    }

    nuxtApp.$eijnsApp.credentials = {
        ...nuxtApp?.$eijnsApp?.credentials,
        ...credentials,
    }

    nuxtApp.$eijnsApp.navigation = {
        ...nuxtApp?.$eijnsApp?.navigation,
        rantomat: navigation,
    }

    nuxtApp.$eijnsApp.notifications = {
        ...nuxtApp?.$eijnsApp?.notifications,
        ...notifications,
    }

    nuxtApp.$eijnsApp.setupPages ??= {}
    nuxtApp.$eijnsApp.setupPages.rantomat = setupPage

    nuxtApp.$eijnsApp.setupSave ??= {}
    nuxtApp.$eijnsApp.setupSave.rantomat = async (data) => {
        console.log(data)
        return data
    }

    console.log(chalk.yellow(`${dayjs().format('YYYY-MM-DD HH:mm:ss.SSS')} ...`))
    console.log(chalk.blue(`${__filename} ... defineNuxtPlugin()`))
    console.log('rantomat-module plugin injected')
    //console.log(nuxtApp.$eijnsApp)
})